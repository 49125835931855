<template>
    <div class="data-download">
        <img
            class="img-banner"
            src="../assets/data-download/banner.jpg"
            alt=""
        />
        <div class="title-block">
            <div class="title font-bold">
                下載專區
                <div class="red-line-l"></div>
            </div>
        </div>
        <div class="link-block">
            <div class="link-list">
                <ol>
                    <li>
                        <a
                            class="link"
                            target="_blank"
                            href="../pdf/工會入會申請書.pdf"
                            >入會申請書<span class="tips">（按此下載）</span>
                        </a>
                    </li>
                    <li>
                        <a
                            class="link"
                            target="_blank"
                            href="../pdf/臺北市配音人員職業工會組織章程.pdf"
                            >臺北市配音人員職業工會組織章程<span class="tips"
                                >（按此下載）</span
                            ></a
                        >
                    </li>
                    <li>
                        <a
                            class="link"
                            target="_blank"
                            href="../pdf/110年度急難慰問計畫.pdf"
                            >110年度急難慰問計畫<span class="tips"
                                >（按此下載）</span
                            ></a
                        >
                    </li>
                </ol>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {};
    },
    computed: {},
    created() { },
    mounted() { },
    methods: {},
};
</script>

<style lang="scss" scoped>
.data-download {
    display: flex;
    flex-direction: column;
}

.img-banner {
    width: 100vw;
}

.title-block {
    padding: 82px 0;
    width: 100vw;
    background-color: #BDA996;
    display: flex;
    justify-content: center;
    align-items: center;

    .title {
        font-size: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

.link-block {
    padding: 147px 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .link-list {
        font-size: 20px;
        line-height: 30px;
        ol {
            list-style: decimal;
            li:not(:last-child) {
                margin-bottom: 25px;
            }

            .link {
                text-decoration: none;
                color: black;
                display: block;
            }

            .link:hover {
                text-decoration: underline;
            }
        }
    }
}

@media screen and (max-width: 960px) {
    .title-block {
        padding: 46px 0;

        .title {
            font-size: 28px;
        }
    }

    .link-block {
        padding: 25px;
        .link-list {
            background-color: #f9fafb;
            box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.25);
            border-radius: 6px;
            font-size: 16px;
            width: 90vw;
            padding: 10%;

            ol {
                list-style: none;
                display: flex;
                flex-direction: column;
                align-items: center;

                li:not(:last-child) {
                    margin-bottom: 10px;
                }
                .tips {
                    display: none;
                }
            }
        }
    }
}
</style>
